const pricing = {
    startNow: '立即开始',
    chooseA: '选择最适合您的',
    plan: '方案',
    thatWorksBest: '',
    subTitle: '几分钟内，找到并开始与您的产品合适的海外KOL合作。',
    monthly: '每月',
    quarterly: '每季度',
    annually: '每年',
    perMonth: '/月',
    usdPerMonth: '美金/月',
    rmbPerMonth: '人民币/月',
    startFreeTrial: '开始免费试用',
    contactNow: '立即联系',
    checkOutOur: '查看我们的',
    blog: '博客',
    andLearnHowToGetTheMost: '，了解如何从您的订阅中获得最大价值。',

    upTo_amount_Searches: '{{amount}}次KOL搜索',
    amount_InfluencerAudienceReports: '{{amount}}次KOL受众报告',
    campaignManagementTool: '项目管理工具',
    amount_AIGeneratedEmailTemplates: '{{amount}}个AI生成的电子邮件模板',
    fullCustomerService: '完整的客户服务',
    boostBotSearchAndNormalSearch: 'BoostBot AI搜索 + 传统搜索',
    personalEmailAccount: '{{amount}}个个性化电子邮件帐户',
    amount_EmailsPerMonth: '每月可向{{amount}}个KOL发送电子邮件',

    upgrade: '立即升级',
    buyNow: '立即购买',
    contactUs: '联系我们',
    popular: '热门',
    yourCurrentPlan: '您当前的方案',
    invalidCoupon: '无效的优惠券',
    couponApplied: '优惠券已应用',
    applyCoupon: '应用优惠券',
    discount: '折扣',
    forThoseLookingToScale: '适合扩展业务的人',
    billedQuarterly: '每季度计费',
    billedMonthly: '每月计费',
    thirtyDayFreeTrial: '30天免费试用',
    noChargeToday: '今天不收费！',
    getStartedWithYourFreeTrial: '开始免费试用',
    justGettingStartedOrScalingUp: '刚开始或正在扩展业务',
    relayClubCanHelp: 'BoostBot雷宝可以帮助',
    freePrice: '免费',
    diy: {
        title: 'DIY',
        subTitle: '适合刚开始的团队',
    },
    diyMax: {
        title: 'DIY Max',
        subTitle: '适合想要扩展业务的团队',
    },
    free: {
        title: '免费试用',
        subTitle: '查看我们可以提供什么',
    },
    discovery: {
        title: '探索方案',
        subTitle: 'AI搜索助力销售增长：立即开始探索方案，寻找驱动电商销售增长的红人',
    },
    outreach: {
        title: '拓展方案',
        subTitle: '费时少，收效大：自动化邮件进程，大批量联系和管理红人',
    },
    saveUpTo33Percent: '高达33%折扣',
    beta: 'BETA',
    backToAccount: '回到账号',
};

export default pricing;
