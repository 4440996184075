const inbox = {
    title: '收件箱',
    noMessagesInMailbox: '此邮箱中尚无邮件',
    unread: '未读',
    inbox: '收件箱',
    searchMessages: '搜索邮件',
    searchPlaceholder: '搜索',
    send: '发送',
    replyPlaceholder: '输入您的消息以回复',
    from: '发件人',
    to: '收件人',
    cc: '抄送',
    sequence: '项目',
    lastMessage: '最后信息',
    product: '产品名称',
};

export default inbox;
