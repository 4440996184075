const navbar = {
    campaigns: 'Campaigns',
    discover: 'Classic',
    aiEmailGenerator: 'AI Email Generator',
    account: 'My Account',
    logout: 'Log Out',
    performance: 'Performance',
    boostbot: 'BoostBot',
    guide: 'Guide',
    sequences: 'CRM',
    inbox: 'Inbox',
    influencerManager: 'Manager',
    report: "{{influencerName}}'s Report",
};

export default navbar;
