const login = {
    logIn: 'Log in',
    signUp: 'Sign up',
    signOut: 'Sign out',
    welcomeBack: 'Welcome back!',
    email: 'Email',
    password: 'Password',
    passwordPlaceholder: 'Enter your password',
    dontHaveAnAccount: "Don't have an account? ",
    alreadyHaveAnAccount: 'Already have an account? ',
    stuckHereTryAgain1: 'Stuck here? ',
    stuckHereTryAgain2: ' and try again',
    loginSuccess: 'Successfully logged in',
    oopsSomethingWentWrong: 'Oops! Something went wrong',
    signupSubtitle: 'Find the perfect influencers to endorse your product! 30 day free trial.',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    confirmPassword: 'Confirm password',
    someoneInvitedYouToJoinRelayClub:
        'Someone invited you to join BoostBot. Accept the invitation by setting up an account below.',
    inviteAccepted: 'Invite accepted',
    checkingInviteStatus: 'Checking invite status',
    back: 'Back',
    acceptInvite: 'Accept invite',
    noInviteTokenFound: 'No invite token found. Please use the link sent to your email to accept the invitation',
    inviteInvalid: 'Invite is invalid or expired',
    inviteUsed: 'Invite already used',
    inviteExpired: 'Invite is expired',
    inviteAlreadyExists: 'Invite already exists',
    invalidEmail: 'Invalid email',
    missingRequiredFields: 'Missing required fields',
    userAlreadyExists: 'User already exists',
    inviteExistsAndHasNotExpired: 'Invite already exists and has not expired',
    createYourCompanyProfile: 'Create your company profile',
    companyCreated: 'Company created',
    onboardCompany: 'Onboard Company',
    addCompanyDetails: 'Add company details',
    companyName: 'Company name',
    companyNamePlaceholder: "Enter your company's name",
    companyWebsite: 'Company website',
    companyWebsitePlaceholder: "Enter your company website's url",
    createCompany: 'Create company',
    addPaymentMethod: 'Add payment method',
    andActivateTrial: 'And activate your 30 day free trial.',
    activateTrial: 'Start Free Trial',
    userAlreadyRegistered: 'User already registered',
    signupTerms:
        'In order to activate this 30-day free trial, you must sign up with your payment details and agree to boostbot.ai terms and services. You will be charged for a monthly DIY subscription after 30 days unless you deactivate payment before the end of the trial period.',
    accountActivated: 'Account activated',
    noLoggedInUserFound: 'No logged in user found',
    noCompanyNameFound: 'No company name found',
    companyWithSameNameExists: 'Company with same name already exists',
    passwordNeedsTen: 'Must be at least 10 characters long',
    passwordNeedsNumber: 'Must contain at least one number',
    passwordNeedsSpecial: 'Must contain at least one special character',
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
    emailInvalid: 'Invalid Email',
    passwordsDoNotMatch: 'Passwords do not match',
    invalidPassword: 'Invalid password',
    forgotPassword: 'Forgot password? ',
    resetPassword: 'Reset',
    resetPasswordEmailSent: 'Reset password email sent',
    changePassword: 'Change password',
    inputNewPassword: 'Input new password',
    updateAndLogIn: 'Update and log in',
    passwordUpdated: 'Password updated',
    passwordRequirements:
        'Must be at least 10 characters long and contain at least one number and one special character',
    phoneNumberInvalid: 'Invalid phone number',
    phoneNumber: 'Phone number',
    disclaimer:
        '* At BoostBot, we prioritize your data privacy. We guarantee that your email and mobile phone will never be disclosed or sold to any third parties, and we will solely utilize your email and mobile phone number to reach out for the purpose of arranging a product demonstration.',
    noSpacesAllowed: 'Must not include spaces',
    activateSuccess: 'Success',
    companyNameRequired: 'Company name is required',
};
export default login;
