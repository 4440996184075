const profile = {
    outreach: '联络进度',
    nextStep: '下一步',
    nextStepPlaceholder: '与此KOL合作的下一步安排',
    collabStatus: '合作状态',
    notes: '详情',
    notesPlaceholder: '写下关于此KOL的备注，以备后续查看。',
    collab: '合作详情',
    addNoteButton: '添加另一条备注',
    fee: '报价 (USD)',
    videoDetails: '视频详情',
    videoDetailsPlaceholder: '视频内容或风格',
    scheduledPostDate: '计划发布时间',
    affiliateLink: '联盟营销链接',
    affiliateLinkPlaceholder: '需要KOL需要发布的推广链接',
    posts: '帖子',
    addPostButton: '点击添加新的帖子',
    notesTab: '备注',
    shippingDetailsTab: '发货信息',
    shippingDetails: '发货信息',
    name: ' 姓名（全名）',
    phoneNumber: '电话号码',
    streetAddress: '街道地址',
    city: '城市',
    state: '州/省',
    postalCode: '邮政编码',
    country: '国家',
    trackingCode: '物流编号',
    fullAddress: '完整地址',
    updateProfileButton: '完整地址',
};

export default profile;
