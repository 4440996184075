const performance = {
    title: '数据中心',
    allCampaigns: '所有项目',
    stats: {
        posts: '帖子',
        sales: '销售',
        likes: '点赞',
        comments: '评论',
        views: '浏览',
    },
};

export default performance;
