const pricing = {
    startNow: 'Start Now',
    chooseA: 'Choose the best ',
    plan: 'plan',
    thatWorksBest: ' for you',
    subTitle: 'Within minutes, find and start working with the right influencers for your product.',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    annually: 'Annually',
    perMonth: '/ month',
    usdPerMonth: 'USD / Month',
    rmbPerMonth: 'RMB / Month',
    startFreeTrial: 'Start Your Free Trial',
    contactNow: 'Contact Now',
    checkOutOur: 'Check out our ',
    blog: 'blog',
    andLearnHowToGetTheMost: ' and learn how to get the most value out of your subscription.',

    upTo_amount_Searches: '{{amount}} Influencer Searches',
    amount_InfluencerAudienceReports: '{{amount}} Influencer Audience Reports',
    campaignManagementTool: 'Campaign Management Tool',
    amount_AIGeneratedEmailTemplates: '{{amount}} AI Generated Email Templates',
    fullCustomerService: 'Full Customer Service',
    boostBotSearchAndNormalSearch: 'BoostBot AI Search + Traditional Search',
    personalEmailAccount: '{{amount}} Personalized Email Account',
    amount_EmailsPerMonth: 'Can send emails up to {{amount}} influencers/month',

    upgrade: 'Upgrade',
    buyNow: 'Buy Now',
    contactUs: 'Contact us',
    popular: 'POPULAR',
    yourCurrentPlan: 'Your current plan',
    invalidCoupon: 'Invalid coupon',
    couponApplied: 'Coupon applied',
    applyCoupon: 'Apply coupon',
    discount: 'Discount',
    forThoseLookingToScale: 'For those looking to scale',
    billedQuarterly: 'Billed quarterly',
    billedMonthly: 'Billed monthly',
    thirtyDayFreeTrial: '30 day free trial',
    noChargeToday: 'No charge today!',
    getStartedWithYourFreeTrial: 'Get started with your free trial',
    justGettingStartedOrScalingUp: 'Just getting started, or scaling up.',
    relayClubCanHelp: 'BoostBot can help.',
    freePrice: 'Free',
    diy: {
        title: 'DIY',
        subTitle: 'For teams just getting started',
    },
    diyMax: {
        title: 'DIY Max',
        subTitle: 'For teams looking to grow',
    },
    free: {
        title: 'Free Trial',
        subTitle: 'Check out what we can offer',
    },
    discovery: {
        title: 'DISCOVERY',
        subTitle: 'From AI Search to Sales: Discover Influencers that Drive E-commerce Success.',
    },
    outreach: {
        title: 'OUTREACH',
        subTitle: 'Maximize Reach, Minimize Effort: Automated Influencer Contacts at Scale.',
    },
    saveUpTo33Percent: 'up to 33% off',
    beta: 'BETA',
    backToAccount: 'Back to account',
};

export default pricing;
