export const faq = {
    sequences: [
        {
            title: '邮件是否会在我不知情的情况下发送？',
            detail: '当然不会！在您添加KOL至邮件进程管理后，它们将显示”急需回复“状态。只有在您点击“开始邮件进程”按钮后，邮件才会被发送。 \n**您也可以选择打开“开启自动处理”按钮。设置后，如果添加的KOL已有邮件地址，系统可以自动安排给红人发送邮件。',
        },
        {
            title: '发送邮件的邮箱地址是什么？',
            detail: '我们为您设置了一个专用的联系邮箱，该邮箱使用的是我们专属的推广邮件子域。一旦您完成”拓展方案“的付费订阅，我们将联系您进行设置，并将邮箱地址添加至您的账户',
        },
        {
            title: '为什么我还是无法从邮件进程管理中发送邮件？',
            detail: '首先您需要确保您已付费订阅”拓展方案“，并由我们的同事帮您设置好联系邮箱，接下来您就可以开始联络红人了。步骤如下： \n（1）首先确认KOL报告页中是否包含有效邮箱地址，并且为KOL添加”急需回复“状态； \n（2）其次，请完整填写“模版”中的所有必填项； \n请注意，免费试用与”探索方案“用户无法使用邮件发送功能，需付费升级至”探索方案“。',
        },
        {
            title: '我应该设置多少个邮件进程管理？',
            detail: '我们建议您为想合作的垂类领域KOL，分别创建项目进行管理。为提高转化率，最好根据KOL垂类特性自定义模版变量内容。 \n我们会帮你做好KOL管理，保持有条不紊！回复您的KOL邮件都将被导入到“KOL管理跟进”中，所有项目的KOL回复都会出现在这里。',
        },
        {
            title: '发送的邮件内容是什么？',
            detail: '邮件进程管理中使用的是由我们团队编写的联系模版，是经过我们长时间实践和反复验证的有效模版。\n 您可以在邮件进程管理中点击“浏览模版”查看邮件内容。',
        },
    ],
    sequencesTitle: '邮件进程常见问题解答',
    sequencesGetMoreInfo: '获取邮件进程管理的更多信息',
    influencerManager: [
        {
            title: '这个功能跟邮件进程管理有什么不一样？',
            detail: '这里展示的只有已经回复了您的邮件的KOL。在这是，您可以集中精力，与这些KOL进行沟通谈判，进一步确认合作关系！',
        },
        {
            title: '我应该做什么呢？',
            detail: "KOL管理跟进是活跃KOL的管理中心，在这里您可以纵观所有KOL的进展。使用状态来跟踪每个人的进展，比如，您可以向“已确认”的KOL寄送产品，或者与已“收到产品”并正在制作内容的KOL进行催促和跟进。通过点击KOL并访问他们的'KOL资料'，您还可以进一步添加备注、合作细节、物流信息等。这些信息也会显示在您的收件箱页面，以便轻松找到和查看！",
        },
        {
            title: '我的团队成员都可以看到我记录的KOL资料嘛？',
            detail: '没错！您团队中的每一个人都可以访问这个资料。您可以通过切换“仅查看我联系的KOL”按钮，来筛选出跟您进行邮件联络的KOL。',
        },
        {
            title: '如果我想针对某一个KOL记录信息呢？',
            detail: '可以使用“KOL资料”进行记录。点击任意一个KOL就可以在同一个页面展示完整的KOL资料。如果你有一个需要记录的东西，但是无法找到地方记录，可以添加在备注里。',
        },
        {
            title: '我在哪里可以看到这些KOL的邮件内容？',
            detail: '电子邮件会显示在“收件箱”页面中。点击右侧的收件箱图标，就可以查看电子邮件内容并回复。',
        },
    ],
    influencerManagerTitle: 'KOL管理跟进常见问题解答',
    influencerManagerDescription: '只需点击一下，即可发送定制化的联络邮件和跟进邮件',
    influencerManagerGetMoreInfo: '获取KOL管理跟进的更多信息',
};

export default faq;
