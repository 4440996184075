const banner = {
    outreach: {
        title: 'Outreach Plan Exclusive Feature :',
        descriptionManager: 'Influencer Manager is only available for Outreach Plan accounts.',
        descriptionInbox: 'Inbox is only available for Outreach Plan accounts.',
        descriptionSequences: 'Sending sequence emails is only available for Outreach Plan accounts.',
    },
    expired: {
        title: 'Your free trial has expired.',
        description: 'Please upgrade your account to use this feature.',
    },
    button: 'Upgrade now',
};

export default banner;
