const geolocations = {
    countries: {
        CA: '加拿大',
        FR: '法国',
        RU: '俄罗斯',
        US: '美国',
        GB: '英国',
        ES: '西班牙',
        BR: '巴西',
        MX: '墨西哥',
        DE: '德国',
        IT: '意大利',
        IN: '印度',
        ID: '印度尼西亚',
        TR: '土耳其',
        EG: '埃及',
        AE: '阿拉伯联合酋长国',
        AU: '澳大利亚',
        PT: '葡萄牙',
        GR: '希腊',
        IR: '伊朗',
        AT: '奥地利',
        JP: '日本',
        PE: '秘鲁',
        CH: '瑞士',
        PL: '委内瑞拉',
        VE: '伊拉克',
        IQ: '沙特阿拉伯',
        SA: '波兰',
        AR: '阿根廷',
        CO: '哥伦比亚',
        CN: '中国',
        DZ: '阿尔及利亚',
        TH: '泰国',
        BE: '比利时',
        PH: '菲律宾',
        CL: '智利',
        NG: '尼日利亚',
        NL: '荷兰',
        SE: '瑞典',
        SG: '新加坡',
        ZA: '南非',
        KR: '韩国',
        AZ: '阿塞拜疆',
        DO: '多米尼加共和国',
        MY: '马来西亚',
        UA: '乌克兰',
        MA: '摩洛哥',
        PK: '巴基斯坦',
        IL: '以色列',
        JM: '牙买加',
        VN: '越南',
        GT: '危地马拉',
        PY: '巴拉圭',
        TN: '突尼斯',
        BG: '保加利亚',
        GE: '格鲁吉亚',
        LK: '巴勒斯坦',
        PS: '斯里兰卡',
        UZ: '乌兹别克斯坦',
        TW: '台湾',
        TZ: '坦桑尼亚',
        KE: '肯尼亚',
        UY: '乌拉圭',
        KW: '科威特',
        NO: '挪威',
        FI: '芬兰',
        EC: '厄瓜多尔',
        JO: '约旦',
        BD: '孟加拉国',
        QA: '卡塔尔',
        OM: '阿曼',
        LY: '利比亚',
        HU: '匈牙利',
        PA: '巴拿马',
        CR: '哥斯达黎加',
        KZ: '哈萨克斯坦',
        IE: '爱尔兰',
        KG: '吉尔吉斯斯坦',
        BY: '白俄罗斯',
        HR: '克罗地亚',
        NZ: '新西兰',
        LB: '黎巴嫩',
        GH: '加纳',
        DK: '丹麦',
        CZ: '捷克共和国',
        RS: '塞尔维亚',
        RO: '罗马尼亚',
        CY: '塞浦路斯',
        TT: '特立尼达和多巴哥',
        MZ: '莫桑比克',
        LT: '立陶宛',
        YE: '也门',
        AO: '安哥拉',
        BA: '波斯尼亚和黑塞哥维那',
        XK: '科索沃',
        SY: '索马里',
        NA: '叙利亚',
        MV: '纳米比亚',
        SO: '马尔代夫',
        UG: '乌干达',
        SD: '苏丹',
        CM: '喀麦隆',
        SV: '萨尔瓦多',
        BS: '巴哈马',
        SN: '塞内加尔',
        HN: '洪都拉斯',
        HT: '海地',
        CI: '科特迪瓦',
        SI: '斯洛文尼亚',
        CD: '刚果民主共和国',
        BO: '玻利维亚',
        TJ: '塔吉克斯坦',
        CU: '古巴',
        AL: '阿尔巴尼亚',
        ZW: '津巴布韦',
        AF: '阿富汗',
        AM: '柬埔寨',
        KH: '尼泊尔',
        NP: '巴林',
        MD: '拉脱维亚',
        BH: '斯洛伐克',
        LV: '亚美尼亚',
        SK: '摩尔多瓦',
        BB: '巴巴多斯',
        MU: '毛里求斯',
        MC: '摩纳哥',
        BN: '文莱',
        MK: '马其顿',
        ZM: '赞比亚',
        MT: '马耳他',
        ME: '黑山',
        EE: '爱沙尼亚',
        LA: '老挝',
        CG: '刚果 - 布拉柴维尔',
        RW: '卢旺达',
        IS: '冰岛',
        ET: '埃塞俄比亚',
        BJ: '贝宁',
        TG: '多哥',
        NI: '尼加拉瓜',
        LU: '卢森堡',
        MM: '缅甸',
        GN: '几内亚',
        ML: '马里',
        TM: '土库曼斯坦',
        LC: '圣卢西亚',
        GY: '圭亚那',
        BF: '布基纳法索',
        BI: '布隆迪',
        SZ: '斯威士兰',
        GM: '冈比亚',
        NE: '尼日尔',
        TD: '乍得',
        AD: '毛里塔尼亚',
        CV: '塞拉利昂',
        MR: '圣文森特和格林纳丁斯',
        SL: '安道尔',
        VC: '佛得角',
        GA: '加蓬',
        MW: '马拉维',
        MN: '蒙古',
        BM: '百慕大',
        MG: '马达加斯加',
        KY: '开曼群岛',
        GD: '格林纳达',
        BZ: '伯利兹',
        LR: '利比里亚',
        BW: '博茨瓦纳',
        FJ: '斐济',
        SR: '苏里南',
        TC: '特克斯和凯科斯群岛',
        AG: '安提瓜和巴布达',
        GI: '直布罗陀',
        VA: '梵蒂冈城',
        KP: '北朝鲜',
        SS: '南苏丹',
        DM: '多米尼加',
        LS: '莱索托',
        DJ: '吉布提',
        JE: '泽西岛',
        VG: '英属维尔京群岛',
        PG: '巴布亚新几内亚',
        SC: '塞舌尔',
        TL: '东帝汶',
        GQ: '赤道几内亚',
        KN: '圣基茨和尼维斯',
        BT: '不丹',
        GG: '根西岛',
        CF: '中非共和国',
        IM: '马恩岛',
        VU: '瓦努阿图',
        WS: '萨摩亚',
        SB: '所罗门群岛',
        AI: '安圭拉',
        FO: '法罗群岛',
        ER: '厄立特里亚',
        SM: '圣马力诺',
        LI: '列支敦士登',
        ST: '圣多美和普林西比',
        CK: '库克群岛',
        GW: '几内亚比绍',
        KM: '科摩罗',
        GL: '格陵兰岛',
        SH: '圣赫勒拿、阿森松和特里斯坦达库尼亚',
        PW: '帕劳',
        TO: '汤加',
        MS: '蒙特塞拉特',
        FM: '密克罗尼西亚联邦',
        MH: '马绍尔群岛',
        FK: '福克兰群岛',
        NR: '瑙鲁',
        TV: '图瓦卢',
        NU: '纽埃',
        GS: '南乔治亚和南桑德韦奇群岛',
        KI: '基里巴斯',
        PN: '皮特凯恩群岛',
        IO: '英属印度洋领地',
        TK: '托克劳',
    },
};

export default geolocations;
