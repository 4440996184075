export enum CurrentPageEvent {
    index = 'index',
    boostbot = 'boostbot',
    dashboard = 'dashboard',
    sequences = 'sequences',
    inbox = 'inbox',
    'influencer-manager' = 'influencer-manager',
    guide = 'guide',
    account = 'account',
    pricing = 'pricing',
    upgrade = 'upgrade',
    login = 'login',
    signup = 'signup',
    payments = 'payments',
    influencer = 'influencer',
    campaigns = 'campaigns',
    admin = 'admin',
    performance = 'performance',
}
