const performance = {
    title: 'Performance',
    allCampaigns: 'All campaigns',
    stats: {
        posts: 'Posts',
        sales: 'Sales',
        likes: 'Likes',
        comments: 'Comments',
        views: 'Views',
    },
};

export default performance;
