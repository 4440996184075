const inbox = {
    title: 'Inbox',
    noMessagesInMailbox: 'No messages from this Mailbox yet',
    unread: 'Unread',
    inbox: 'All',
    searchMessages: 'Search Messages:',
    searchPlaceholder: 'Search',
    send: 'Send',
    replyPlaceholder: 'Type your message to reply',
    from: 'From',
    to: 'To',
    cc: 'cc',
    sequence: 'Sequence',
    lastMessage: 'Last Message',
    product: 'Product',
};

export default inbox;
