const login = {
    logIn: '登录',
    signUp: '注册',
    signOut: '登出',
    welcomeBack: '欢迎回来！',
    email: '电子邮件',
    password: '密码',
    passwordPlaceholder: '输入您的密码',
    dontHaveAnAccount: '还没有账号？',
    alreadyHaveAnAccount: '已经有账号了？',
    stuckHereTryAgain1: '卡在这里了？',
    stuckHereTryAgain2: '再试一次',
    loginSuccess: '登录成功',
    oopsSomethingWentWrong: '哎呀！出错了',
    signupSubtitle: '立即开启30天免费试用轻松获得国际红人背书，加速品牌出海!',
    firstName: '名字',
    firstNamePlaceholder: '输入您的名字',
    lastName: '姓氏',
    lastNamePlaceholder: '输入您的姓氏',
    confirmPassword: '确认密码',
    someoneInvitedYouToJoinRelayClub: '有人邀请您加入boostbot.ai。通过以下设置接受邀请。',
    inviteAccepted: '邀请已接受',
    checkingInviteStatus: '正在查看邀请状态',
    back: '返回',
    acceptInvite: '接受邀请',
    noInviteTokenFound: '找不到邀请令牌。请使用发送到您的电子邮件的链接接受邀请',
    inviteInvalid: '邀请无效或已过期',
    inviteUsed: '邀请已使用',
    inviteExpired: '邀请已过期',
    inviteAlreadyExists: '邀请已存在',
    invalidEmail: '无效的电子邮件',
    missingRequiredFields: '缺少必填字段',
    userAlreadyExists: '用户已存在',
    inviteExistsAndHasNotExpired: '邀请已存在且尚未过期',
    createYourCompanyProfile: '创建您的公司简介',
    companyCreated: '公司已创建',
    onboardCompany: '入驻公司',
    addCompanyDetails: '添加公司详细信息',
    companyName: '公司名称',
    companyNamePlaceholder: '输入您的公司名称',
    companyWebsite: '公司网站',
    companyWebsitePlaceholder: '输入您的公司网站的网址',
    createCompany: '创建公司',
    addPaymentMethod: '添加付款方式',
    andActivateTrial: '并激活您的30天免费试用。',
    activateTrial: '激活试用',
    userAlreadyRegistered: '用户已注册',
    signupTerms:
        '为了激活此30天免费试用，您必须使用您的付款详细信息注册并同意BoostBot雷宝条款和服务。如果您在试用期结束前未停用付款，您将在30天后被收取每月DIY订阅费。',
    accountActivated: '帐户已激活',
    noLoggedInUserFound: '找不到登录用户',
    noCompanyNameFound: '找不到公司名称',
    companyWithSameNameExists: '已存在具有相同名称的公司',
    passwordNeedsTen: '密码需要至少10个字符',
    passwordNeedsNumber: '密码需要至少1个数字',
    passwordNeedsSpecial: '密码需要至少1个特殊字符',
    firstNameRequired: '名字是必填字段',
    lastNameRequired: '姓氏是必填字段',
    emailInvalid: '电子邮件无效',
    passwordsDoNotMatch: '密码不匹配',
    forgotPassword: '忘记密码? ',
    resetPassword: '重置',
    resetPasswordEmailSent: '重置密码电子邮件已发送',
    changePassword: '更改密码',
    inputNewPassword: '输入新密码',
    updateAndLogIn: '更新并登录',
    passwordUpdated: '密码已更新',
    passwordRequirements: '密码必须至少包含10个字符，其中至少1个特殊字符，1个数字',
    phoneNumberInvalid: '电话号码无效',
    phoneNumber: '电话号码',
    disclaimer:
        '* 在BoostBot雷宝，我们非常注重保护您的数据隐私。我们承诺不会向任何第三方透露或出售您的邮箱和手机号码。我们可能会使用您的邮箱和手机号码联系您，以便安排产品演示，为您提供更好的使用体验。',
    noSpacesAllowed: '不许包含空格',
    activateSuccess: '激活成功',
    companyNameRequired: '公司名称是必填字段',
};
export default login;
